import React from 'react';

const Title = () => {
  return (
    <h1 className="wmnds-col-1 wmnds-col-lg-3-4">
      Reinstate your Direct Debit (COVID-19)
    </h1>
  );
};

export default Title;
